import React, { useEffect, useState } from "react";
import styles from "./Promote.module.scss";

const Promote = () => {
  const BACKEND_URL = "http://localhost:8001/api";

  // useEffect(async () => { }, []);

  return <div>Promote</div>;
};

export default Promote;
